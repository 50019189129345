import moment from 'moment';
import TimeField from 'react-simple-timefield';
import React, { useRef } from 'react';
import { Typography, Row, Col, Space, Empty, Tooltip, Card, Table, Popover, Form, Input, Select, Modal, Button, DatePicker, Tabs, List, Popconfirm, Divider } from 'antd';
import { StopOutlined, SearchOutlined, CheckOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

moment.locale('ru');

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;
const { Link } = Typography;
const Global = props => {

    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    form2.setFieldsValue({ search: props.state.search });

    let { filteredInfo } = props.state;
    filteredInfo = filteredInfo || {};
    
    var obj = {
        //* Поиск
        get recordlocal () {
            if ( props.state.recordslocal.length > 0 ) {
                let search = props.state.search.toLowerCase();
                return props.state.recordslocal.filter( function( item ) {
                    return(
                        item['phone'].toLowerCase().indexOf( search ) > -1 ||
                        item['client_lastname'].toLowerCase().indexOf( search ) > -1 ||
                        item['client_firstname'].toLowerCase().indexOf( search ) > -1 ||
                        item['client_patronymic'].toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return props.state.recordslocal;
            }
        }
    }
    
    function titleblock(n) {
        switch(n) {
          case '0': return <div className="cal-badge cal-badge-warning">Рассматривается</div>;
          case '1': return <div className="cal-badge cal-badge-close">Подтверждено</div>;
          case '2': return <div className="cal-badge cal-badge-danger">Отменён</div>;
          case '3': return <div className="cal-badge cal-badge-danger">Не дозвонились</div>;
          default: return <div className="cal-badge">Неизвестно</div>;
        }
    }

    const titlefilter = n => {
        switch(n) {
            case '0': return 'Рассматривается';
            case '1': return 'Подтверждено';
            case '2': return 'Отменён';
            case '3': return 'Не дозвонились';
            default: return 'Неизвестно';
          }
      }
    
    const columns1 = [
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            render: (text) => <Link href={`tel:+${text}`}>{`+${text}`}</Link>,
            width: 100,
        },
        {
            title: 'ФИО',
            width: 100,
            render: (record) => (`${record.client_lastname.length > 0 ? record.client_lastname:''}${record.client_firstname.length > 0 ? ' '+record.client_firstname:'' }${record.client_patronymic.length > 0 ? ' '+record.client_patronymic:''}`)
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => props.lasttime(text,'Неизвестно когда','full'),
            width: 100,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (text) => titleblock(text),
            width: 100,
            filters: obj.recordlocal.map(function(item){return {text:titlefilter(item.status),value:item.status}}).map(JSON.stringify).reverse().filter(function (e, i, a) {return a.indexOf(e, i+1) === -1;}).reverse().sort().map(JSON.parse),
            filteredValue: filteredInfo.status || null,
            onFilter: (value, record) => record.status.includes(value),
            sorter: (a, b) => a.status.localeCompare(b.status),
        }
    ];

    async function reclpush(id) {
        await form1.resetFields();
        await props.api({
            'method':'recordslocal_get',
            'reclid':id
        },false);
        await props.panel('consult_view');
    }

    //* Отправка формы
    async function reclupd(v) {
        v['method'] = 'recordslocal_upd';
        v['date'] = Date.parse(props.fulltime(v['date'],v['time']))/1000;
        await props.api(v,true);
    }

    //* Полное время 
    function fulltime(time) {
        let date = new Date(Number(time)*1000);
        let d1 = (date.getDate()<10?'0':'') + date.getDate();
        let d2 = date.getMonth()+1;
        d2 = (d2<10?'0':'') + d2;
        let d3 = date.getFullYear();
        let d4 = (date.getHours()<10?'0':'') + date.getHours();
        let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
        return `${d3}/${d2}/${d1} ${d4}:${d5}`;
    }

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    return (
        <React.Fragment>
            <Modal className="modal-mini" width="100%" zIndex="900" key="m1" title="Онлайн-заявка" visible={props.state.panel.indexOf( 'consult_view' ) != -1 } onOk={() => props.panel('consult_view')} onCancel={() => props.panel('consult_view')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Онлайн-заявка</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {Object.keys(props.state.recordget).length > 0 ? (
                                <div className="tworow">
                                    {'id' in props.state.recordget ?
                                        <div>
                                            <Divider key="d1" >Контактные данные</Divider>
                                            <Form {...layout} style={{padding:24}} name="form" form={form1} className="form">
                                                {
                                                    form1.setFieldsValue({
                                                        client_lastname:props.state.recordget.client_firstname,
                                                        client_firstname:props.state.recordget.client_firstname,
                                                        client_patronymic:props.state.recordget.client_patronymic,
                                                        patient_name:props.state.recordget.patient_name,
                                                        patient_type:props.state.recordget.patient_type,
                                                        patient_breed:props.state.recordget.patient_breed
                                                    })
                                                }
                                                <Form.Item name="client_lastname" label="Фамилия" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="client_firstname" label="Имя" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="client_patronymic" label="Отчество" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="patient_name" label="Имя питомца" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="patient_type" label="Тип" >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name="patient_breed" label="Порода" >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    :
                                        <Empty description="Информация о профиле недоступна" />
                                    }
                                    {'id' in props.state.recordget ?
                                        <div>
                                            <Divider key="d1" >Информация о заявке</Divider>
                                            <div className="fcenter">
                                                {titleblock(props.state.recordget.status)}
                                            </div>
                                            <Form layout="vertical" style={{padding:24}} name="form" form={form3} onFinish={reclupd} className="form">
                                                {
                                                    form3.setFieldsValue({
                                                        reclid:props.state.recordget.id,
                                                        emp:props.state.recordget.doctor_id,
                                                        complaint:props.state.recordget.complaint,
                                                        status:props.state.recordget.status,
                                                        phone:props.state.recordget.phone,
                                                        records_name:props.state.recordget.records_name,
                                                        records_text:props.state.recordget.records_text
                                                    })
                                                }
                                                {
                                                    Number(props.state.recordget.date) > 0 ? (
                                                        form3.setFieldsValue({ date:moment(fulltime(props.state.recordget.date), "YYYY-MM-DD"),time:moment(fulltime(props.state.recordget.date), "YYYY-MM-DD HH:mm").format('HH:mm') })
                                                    ) : (
                                                        null
                                                    )
                                                }
                                                <Form.Item hidden name={`reclid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name={`records_name`} label='Направление услуги' >
                                                    <TextArea disabled />
                                                </Form.Item>
                                                <Form.Item name={`records_text`} >
                                                    <TextArea disabled />
                                                </Form.Item>
                                                <Form.Item name="complaint" label="Комментарий" >
                                                    <TextArea rows={4} maxLength={1000} showCount />
                                                </Form.Item>
                                                <Form.Item name="emp" label="Врач" >
                                                    <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        <Option value="0">Неизвестно</Option>
                                                        { props.state.emp[1].map( (item, index) => 
                                                            <Option key={index} value={item.id}>{item.name}</Option>
                                                        ) }
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="phone" label="Номер телефона" >
                                                    <Input prefix="+" />
                                                </Form.Item>
                                                <Form.Item name="date" label="Желаемая дата">
                                                    <DatePicker
                                                        format="DD.MM.YYYY"
                                                        className="datepickerfull"
                                                    />
                                                </Form.Item>
                                                <Form.Item name="time" label="Желаемое время">
                                                    <TimeField
                                                        colon=":"
                                                        input={<Input />}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="status" label="Статус" >
                                                    <Select>
                                                        <Option value="0">Рассматривается</Option>
                                                        <Option value="1">Подтверждено</Option>
                                                        <Option value="2">Отменён</Option>
                                                        <Option value="3">Не дозвонились</Option>
                                                    </Select>
                                                </Form.Item>
                                                <div className="fcenter">
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                        </Form.Item>
                                                    </Space>
                                                </div>
                                            </Form>
                                        </div>
                                    :
                                        <Empty description="Информация о заявке недоступна" />
                                    }
                                </div>
                            ) : (
                                <Empty description="Информация о клиенте недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Row className="wowload">
                <Col key="k2_clients" span={24} style={{marginBottom:10}} >
                    <Space wrap>
                        <Form style={{maxWidth:'210px'}} name="form" form={form2} >
                            <Form.Item key="search" name="search"><Input placeholder="Поиск" suffix={<SearchOutlined />} onChange={ props.onChange } /></Form.Item>
                        </Form>
                    </Space>
                </Col>
                <Col key="k2_clients" span={24} className="shadowscreen" >
                    <Table 
                        onChange={props.htc}
                        className="table-padding"
                        locale={{ emptyText: <Empty description="Список пуст" />}} 
                        dataSource={obj.recordlocal} 
                        scroll={{ x: 400 }} 
                        pagination={{ position: ['none', 'bottomLeft'] }}
                        rowKey="table1" 
                        columns={columns1}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {reclpush(record.id)}
                            };
                        }}
                    >
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;