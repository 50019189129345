import React from "react";
import styled from "@emotion/styled";
import { CalendarOutlined, WalletOutlined, UserOutlined, MessageOutlined } from '@ant-design/icons';

const Container = styled.a`
    display: flex;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: ${({ isDragging }) => isDragging ? `2px 2px 1px #e7e7e7` : `0 1px 2px #00000008`};
    padding: 10px 12px;
    min-height: 40px;
    margin-bottom: 10px;
    user-select: none;
    color: #000;
    width: 240px;

    &:hover,
    &:active {
        color: #000;
        text-decoration: none;
        box-shadow: 0 4px 8px #0000001f;
        transition: box-shadow ease-in-out .2s;
    }

    &:focus {
        outline: none;
        border-color: #d2d2d2;
        box-shadow: none;
    }
`;

const Content = styled.div`
    flex-grow: 1;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    word-break: break-word;
`;

const BlockTransaction = styled.div`
    display: flex;
    align-items: center;
    font-family: 'Montserrat-Semibold';
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 6px;
    word-break: break-word;
`;

const BlockDate = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

const BlockIcon = styled.div`

`;

const IconButton = styled.div`
    height: 24px;
    width: fit-content;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #e6ecec;
        border-radius: 4px;
        transition-duration: .2s;
    }

    .count {
        margin-left: 5px;
    }
`;

const BlockIconRight = styled.div`
    margin-left: auto
`;

const BlockDateText = styled.div`
    margin-left: 8px;
    font-size: 13px;
    color: #5c6a70;
    font-family: 'Montserrat-Semibold' !important;
`;

const BlockMoney = styled.div`
    display: flex;
    margin-bottom: 5px;
`;

const BlockMoneyText = styled.div`
    margin-left: 8px;
    font-size: 13px;
    color: #000000;
`;

const BlockUser = styled.div`
    display: flex;
`;

const BlockUserText = styled.div`
    margin-left: 8px;
    font-size: 13px;
    color: #000000;
`;

const BlockChat = styled.div`
    display: flex;
    margin-top: 10px;
    margin-bottom: 5px;
`;

const date_string = (seconds) => {
    let mseconds 	= new Date( seconds * 1000 );
    let monthlist 	= { "00":"января ", "01":"февраля ", "02":"марта ", "03":"апреля ", "04":"мая ", "05":"июня ", "06":"июля ", "07":"августа ", "08":"сентября ", "09":"октября ", "10":"ноября ", "11":"декабря " };
    return ( '0' + mseconds.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + mseconds.getMonth() ).slice( -2 ) ] + mseconds.getFullYear() + ' г., ' + ( '0' + mseconds.getHours() ).slice( -2 ) + ':' + ( '0' + mseconds.getMinutes() ).slice( -2 );
}

export default class TransactionItem extends React.PureComponent {
    render() {
        const { transaction, isDragging, isGroupedOver, provided, transactionGet, state } = this.props;

        return (
            <Container
                isDragging={isDragging}
                isGroupedOver={isGroupedOver}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <Content 
                    onClick={() => transactionGet({id:transaction?.id,deal_id:transaction?.deal_id})}
                >
                    <BlockTransaction>{transaction?.name}</BlockTransaction>

                    <BlockDate>
                        <BlockIcon>
                            <CalendarOutlined className="icon_default" />
                        </BlockIcon>
                        <BlockDateText>
                            {date_string(transaction?.date_create)}
                        </BlockDateText>
                    </BlockDate>

                    <BlockMoney>
                        <BlockIcon>
                            <WalletOutlined className="icon_default" />
                        </BlockIcon>
                        <BlockMoneyText>
                            {transaction?.amount} {state?.deals_info?.currency_symbol}
                        </BlockMoneyText>
                    </BlockMoney>

                    <BlockUser>
                        <BlockIcon>
                            <UserOutlined className="icon_default" />
                        </BlockIcon>
                        <BlockUserText>
                            {transaction?.user_name}
                        </BlockUserText>
                    </BlockUser>

                    <BlockChat>
                        <BlockIconRight>
                            <IconButton>
                                <MessageOutlined className="icon_default" /><span className="count">{transaction?.history_text_count}</span>
                            </IconButton>
                        </BlockIconRight>
                    </BlockChat>
                </Content>
            </Container>
        );
    }
}
