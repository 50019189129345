import React from "react";
import styled from "@emotion/styled";
import { Droppable, Draggable } from "react-beautiful-dnd";
import TransactionItem from "./transaction-item";

const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
    if (isDraggingOver) {
        return '#d5e3e3';
    }
    if (isDraggingFrom) {
        return '#d5e3e3';
    }
    return 'transparent';
};

const Wrapper = styled.div`
  background-color: ${props => getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: inherit;
  padding: 10px;
  border: 10px;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 100%;
  padding-bottom: 0;
  overflow: auto;
`;

const scrollContainerHeight = 250;

const DropZone = styled.div`
    min-height: ${scrollContainerHeight}px;
    padding-bottom: 10px;
`;

const ScrollContainer = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    max-height: ${scrollContainerHeight}px;
`;

const Container = styled.div``;

class InnerTransactionList extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.transactions !== this.props.transactions) {
            return true;
        }

        return false;
    }

    render() {
        const { transactionGet, state } = this.props;

        return this.props.transactions.map((transaction, index) => (
        <Draggable
            key={transaction.id}
            draggableId={transaction.id}
            index={index}
            shouldRespectForceTouch={false}
        >
            {(dragProvided, dragSnapshot) => (
            <TransactionItem
                key={transaction.id}
                transaction={transaction}
                isDragging={dragSnapshot.isDragging}
                isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                provided={dragProvided}
                transactionGet={transactionGet}
                state={state}
            />
            )}
        </Draggable>
        ));
    }
}

class InnerList extends React.Component {
    render() {
        const { transactions, dropProvided, transactionGet, state } = this.props;

        return (
        <Container>
            <DropZone ref={dropProvided.innerRef}>
                <InnerTransactionList 
                    transactions={transactions} 
                    transactionGet={transactionGet}
                    state={state}
                />
                {dropProvided.placeholder}
            </DropZone>
        </Container>
        );
    }
}

export default class TransactionList extends React.Component {
    static defaultProps = {
        listId: "LIST"
    };
    render() {
        const {
            ignoreContainerClipping,
            internalScroll,
            scrollContainerStyle,
            isDropDisabled,
            isCombineEnabled,
            listId,
            listType,
            style,
            transactions,
            title,
            transactionGet,
            state
        } = this.props;

        return (
        <Droppable
            droppableId={listId}
            type={listType}
            ignoreContainerClipping={ignoreContainerClipping}
            isDropDisabled={isDropDisabled}
            isCombineEnabled={isCombineEnabled}
        >
            {(dropProvided, dropSnapshot) => (
            <Wrapper
                style={style}
                isDraggingOver={dropSnapshot.isDraggingOver}
                isDropDisabled={isDropDisabled}
                isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
                {...dropProvided.droppableProps}
            >
                {internalScroll ? (
                <ScrollContainer style={scrollContainerStyle}>
                    <InnerList
                        transactions={transactions}
                        dropProvided={dropProvided}
                        transactionGet={transactionGet}
                        state={state}
                    />
                </ScrollContainer>
                ) : (
                <InnerList
                    transactions={transactions}
                    dropProvided={dropProvided}
                    transactionGet={transactionGet}
                    state={state}
                />
                )}
            </Wrapper>
            )}
        </Droppable>
        );
    }
}
