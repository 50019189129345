import React from 'react';
import {  } from 'antd';
import {  } from '@ant-design/icons';

const Global = props => {
    return (
        <div className="headerfull">
            <div className="logoheader">
                <div className="logo" />
            </div>
        </div>
    )
};

export default Global;