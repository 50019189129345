import React, { useState } from 'react';
import { Menu, Divider, Layout } from 'antd';
import {   } from '@ant-design/icons';

const { Sider, Header } = Layout;

const Global = props => {
    
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    return (
        <Sider width={256} trigger={null} collapsible className="leftmenu" breakpoint="lg">
            <div className="logomenu" />
            <Menu >
                { props.state.menu.length > 0 &&
                    props.state.menu.map( list => <Menu.Item icon={props.icons(list.icon)}  key={list.id} onClick={(e) => props.menugo(e.key)}>{list.name}</Menu.Item>)
                }
                <Divider className="dividerwhite" />
                <Menu.Item icon={props.icons('0')} key="0" onClick={(e) => props.menugo(e.key)}>Выход</Menu.Item>
            </Menu>
        </Sider>
    )
};

export default Global;