import React, { useRef } from 'react';
import  ReactToPrint   from 'react-to-print';
import { Typography, Row, Col, Space, Empty, Tooltip, Popconfirm, Table, Divider, Form, Input, Select, Modal, List, Rate, Button } from 'antd';
import { MinusCircleFilled, SearchOutlined, PlusCircleFilled, ArrowRightOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Link, Text, Title } = Typography;

const Global = props => {

    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    form2.setFieldsValue({ search: props.state.search });

    const [activeitem, setActiveitem] = React.useState('');
    const [activeitemfull, setActiveitemfull] = React.useState([]);

    function vispush(vis) {
        form1.resetFields();
        setActiveitem(`${vis.s_date}_${vis.uid}`);
        setActiveitemfull(vis);
        props.modal('soc_item');
        form1.setFieldsValue({ 
            vk_id:vis.vk_id !== '' ? `https://vk.com/id${vis.vk_id}` : ``,
            vk_name:vis.vk_name,
            phone_vk:vis.phone_vk,
            ok_id:vis.ok_id !== '' ? `https://ok.ru/${vis.ok_id}` : ``,
            email_id:vis.email_id,
            fb_id:vis.fb_id !== '' ? `https://www.facebook.com/${vis.fb_id}` : ``,
            skype_id:vis.skype_id,
            twitter_id:vis.twitter_id !== '' ? `https://twitter.com/${vis.twitter_id}` : ``,
            twitter:vis.twitter,
            mm_id:vis.mm_id !== '' ? `https://my.mail.ru/mail/${vis.mm_id}` : ``,
            insta_id:vis.insta_id !== '' ? `https://www.instagram.com/${vis.insta_id}/` : ``,
            phone:vis.phone,
            ya_id:vis.ya_id,
            title:vis.title,
            url:vis.url,
            referrer:vis.referrer,
            url:vis.url,
            referrer:vis.referrer,
            ip:vis.ip,
            useragent:vis.useragent
        })
    }

    const columns1 = [
        {
            title: 'Посетитель',
            key: 'id',
            render: (record) => <span>{activeitem === `${record.s_date}_${record.uid}` ? <MinusCircleFilled style={{color:'#78cf68',marginRight:'20px'}} /> : <PlusCircleFilled onClick={() => vispush(record)}  style={{color:'#c6c6c6',marginRight:'20px'}} />}{record.vk_id !== "" ? <Link target="_blank" href={`https://vk.com/id${record.vk_id}`}>{record.vk_name}</Link> :
                                record.email_id !== "" ? <Link target="_blank" href={`mailto:${record.email_id}`}>{record.email_id}</Link> :
                                record.phone !== "" ? <Link target="_blank" href={`tel:${record.phone}`}>{record.phone}</Link> :
                                'Пользователь'}</span>,
            width: 100,
        },{
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            render: (text) => <Link target="_blank" href={`tel:${text}`}>{text}</Link>,
            width: 100,
        },{
            title: 'Страница входа',
            key: 'title',
            render: (record) => <Link target="_blank" href={record.url}>{record.title}</Link>,
            width: 100,
        },{
            title: 'Дата/Время',
            dataIndex: 's_date',
            key: 's_date',
            render: (text) => (text > 0 ? props.lasttime(text,'Неизвестно','last') : <Text className="bold" type="danger">Неизвестно</Text>),
            width: 100,
        }
    ];

    var obj = {
        //* Поиск
        get visitslist () {
            if ( props.state.visits.length > 0 ) {
                let search = props.state.search.toLowerCase();
                return props.state.visits.filter( function( item ) {
                    return(
                        item['email_id'].toLowerCase().indexOf( search ) > -1 ||
                        item['fb_id'].toLowerCase().indexOf( search ) > -1 ||
                        item['insta_id'].toLowerCase().indexOf( search ) > -1 ||
                        item['ip'].toLowerCase().indexOf( search ) > -1 ||
                        item['mm_id'].toLowerCase().indexOf( search ) > -1 ||
                        item['ok_id'].toLowerCase().indexOf( search ) > -1 ||
                        item['phone'].toLowerCase().indexOf( search ) > -1 ||
                        item['phone_vk'].toLowerCase().indexOf( search ) > -1 ||
                        item['sitevk_id'].toLowerCase().indexOf( search ) > -1 ||
                        item['skype_id'].toLowerCase().indexOf( search ) > -1 ||
                        item['twitter'].toLowerCase().indexOf( search ) > -1 ||
                        item['twitter_id'].toLowerCase().indexOf( search ) > -1 ||
                        item['vk_id'].toLowerCase().indexOf( search ) > -1 ||
                        item['vk_name'].toLowerCase().indexOf( search ) > -1 ||
                        item['ya_id'].toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return props.state.visits;
            }
        }
    }

    async function siteChange(value) {
        await props.api({ 'method':'soc_list', 'hid':value },false);
    }

    return (
        <React.Fragment>
            <Modal mask={false} className="modal-mini bg_chester" width={500} key="m1" title="Просмотр посещения" visible={props.state.modal === 'soc_item'} onOk={() => {props.setState( { modal:'' } );setActiveitem('');setActiveitemfull([])}} onCancel={() => {props.setState( { modal:'' } );setActiveitem('');setActiveitemfull([])}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Просмотр посещения</div>
                </div>
                <div className="modal-mini-body">
                    <Form className='form' name="form" form={form1} layout="vertical">
                        {activeitemfull['vk_id'] !== '' && <Form.Item label="ВКонтакте" key="vk_id" name="vk_id"><Input placeholder="" suffix={<Link target="_blank" href={`https://vk.com/${activeitemfull['vk_id']}`}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['vk_name'] !== '' && <Form.Item label="ВКонтакте" key="vk_name" name="vk_name"><Input placeholder="" /></Form.Item> }
                        {activeitemfull['phone_vk'] !== '' && <Form.Item label="Телефон из ВКонтакте" key="phone_vk" name="phone_vk"><Input placeholder="" suffix={<Link target="_blank" href={`tel:${activeitemfull['phone_vk']}`}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['ok_id'] !== '' && <Form.Item label="Одноклассники" key="ok_id" name="ok_id"><Input placeholder="" suffix={<Link target="_blank" href={`https://ok.ru/${activeitemfull['ok_id']}`}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['email_id'] !== '' && <Form.Item label="Email" key="email_id" name="email_id"><Input placeholder="" suffix={<Link target="_blank" href={`mailto:${activeitemfull['email_id']}`}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['fb_id'] !== '' && <Form.Item label="Facebook" key="fb_id" name="fb_id"><Input placeholder="" suffix={<Link target="_blank" href={`https://www.facebook.com/${activeitemfull['fb_id']}`}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['skype_id'] !== '' && <Form.Item label="Skype" key="skype_id" name="skype_id"><Input placeholder="" suffix={<Link target="_blank" href={`skype:${activeitemfull['skype_id']}`}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['twitter_id'] !== '' && <Form.Item label="Twitter" key="twitter_id" name="twitter_id"><Input placeholder="" suffix={<Link target="_blank" href={`https://twitter.com/${activeitemfull['twitter_id']}`}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['twitter'] !== '' && <Form.Item label="Twitter" key="twitter" name="twitter"><Input placeholder="" /></Form.Item> }
                        {activeitemfull['mm_id'] !== '' && <Form.Item label="Мой Мир" key="mm_id" name="mm_id"><Input placeholder="" suffix={<Link target="_blank" href={`https://my.mail.ru/mail/${activeitemfull['mm_id']}`}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['insta_id'] !== '' && <Form.Item label="Instagram" key="insta_id" name="insta_id"><Input placeholder="" suffix={<Link target="_blank" href={`https://www.instagram.com/${activeitemfull['insta_id']}/`}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['phone'] !== '' && <Form.Item label="Телефон" key="phone" name="phone"><Input placeholder="" suffix={<Link target="_blank" href={`tel:${activeitemfull['phone']}`}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['ya_id'] !== '' && <Form.Item label="Yandex" key="ya_id" name="ya_id"><Input placeholder="" /></Form.Item> }
                        {activeitemfull['title'] !== '' && <Form.Item label="Заголовок" key="title" name="title"><Input placeholder="" /></Form.Item> }
                        {activeitemfull['url'] !== '' && <Form.Item label="Страница входа" key="url" name="url"><Input placeholder="" suffix={<Link target="_blank" href={activeitemfull['url']}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['referrer'] !== '' && <Form.Item label="Источник перехода" key="referrer" name="referrer"><Input placeholder="" suffix={<Link target="_blank" href={activeitemfull['referrer']}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['ip'] !== '' && <Form.Item label="IP" key="ip" name="ip"><Input placeholder="" suffix={<Link target="_blank" href={`https://www.infobyip.com/ip-${activeitemfull['ip']}.html`}><ArrowRightOutlined /></Link>} /></Form.Item> }
                        {activeitemfull['useragent'] !== '' && <Form.Item label="Браузер" key="useragent" name="useragent"><Input placeholder="" /></Form.Item> }
                    </Form> 
                </div>
                
            </Modal>
            <Row className="wowload">
                <Col key="k2_clients" span={24} style={{padding:20}} className="shadowscreen" >
                    <Form style={{maxWidth:'420px'}} name="form" form={form2} layout="inline">
                        <Form.Item style={{maxWidth:'210px'}} key="search" name="search"><Input placeholder="Поиск по номеру / ФИО" suffix={<SearchOutlined />} onChange={ props.onChange } /></Form.Item>
                        <Form.Item style={{maxWidth:'210px',flex:'1'}} key="sites" name="sites">
                            <Select onChange={siteChange}>
                                { props.state.sites.map( item => 
                                    <Option key={`option-${item.hid}`} value={item.hid}>
                                        {item.name}
                                    </Option>
                                ) }
                            </Select>
                        </Form.Item>
                    </Form>
                    <Divider key="d1" orientation="left">Список посещений</Divider>
                    <Table locale={{ emptyText: <Empty description="Список пуст" />}} dataSource={obj.visitslist} scroll={{ x: 800 }} rowKey="table1" columns={columns1} ></Table>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;