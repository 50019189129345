import React, { Component } from "react";
import { Dropdown, Menu, Popconfirm, Typography } from 'antd';
import styled from "@emotion/styled";
import { Draggable } from "react-beautiful-dnd";
import TransactionList from "./transaction-list";
import { DeleteOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons';
const { Paragraph } = Typography;

const Title = styled.h4`
    background-color: ${({ color }) =>
    color ? color : '#c2eef7'};
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
    padding: 3px 8px;
    overflow: hidden;
    white-space: nowrap;
    height: 24px;
    margin-right: 5px;
    margin: auto 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
    display:block;
    padding: 10px;
    height: fit-content;
    width: 100%;
    background-color: ${({ isDragging }) =>
        isDragging ? '#d5e3e3' : 'transparent'};
    transition: background-color 0.2s ease;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

const FlexHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Tools = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    margin-left: auto;
`;

const HeaderButton = styled.div`
    height: 24px;
    width: 24px;

    &:hover {
        background-color: #e6ecec;
        border-radius: 4px;
        transition-duration: .2s;
    }
`;

const CounterMoney = styled.div`
    padding: 0 10px;
    background-color: ${({ isDragging }) =>
        isDragging ? '#d5e3e3' : 'transparent'};
    transition: background-color 0.2s ease;
`;

const Money = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eaeff1;
    border-radius: 6px;
    height: 30px;
    font-size: 13px;
    color: #5c6a70;
    width: 240px;
`;

function decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

export default class Column_droppable extends Component {
  render() {
    const id = this.props.id;
    const title = this.props.title;
    const status = this.props.status;
    const transactions = this.props.transactions;
    const index = this.props.index;
    const transactionAdd = this.props.transactionAdd;
    const transactionGet = this.props.transactionGet;
    const state = this.props.state;
    const statusEdit = this.props.statusEdit;
    const statusDel = this.props.statusDel;

    const status_menu = (status) => (
        <Menu>
            <Menu.Item key="0" onClick={() => statusEdit(status)}>
                <EditOutlined /> Редактировать
            </Menu.Item>
            <Menu.Item disabled={Number(status.transactions_count)} danger key="1">
                <Popconfirm disabled={Number(status.transactions_count)} placement="topRight" onConfirm={() => statusDel(status.id)} title={`Вы действительно хотите удалить «${status.name}»?`} okText="Да" cancelText="Нет">
                    <DeleteOutlined /> Удалить
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (
      <Draggable draggableId={title} index={index}>
        {(provided, snapshot) => (
          <Container ref={provided.innerRef} {...provided.draggableProps}>
            <Header 
                isDragging={snapshot.isDragging}
                {...provided.dragHandleProps}
            >
                <FlexHeader>
                    <Title
                        isDragging={snapshot.isDragging}
                        color={status.color}
                    >
                        {title}
                    </Title>
                    <Tools>
                        <HeaderButton onClick={() => transactionAdd({status_id:id})}>
                            <svg _ngcontent-jlh-c215="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><line _ngcontent-jlh-c215="" x1="12" y1="6" x2="12" y2="18" stroke="#688FA1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line><line _ngcontent-jlh-c215="" x1="6" y1="12" x2="18" y2="12" stroke="#688FA1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line></svg>
                        </HeaderButton>
                        <Dropdown overlay={status_menu(status)} trigger={['click']}>
                            <HeaderButton>
                                <svg _ngcontent-jlh-c216="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-jlh-c216="" d="M16.5938 13.4062C16.1875 13 15.9844 12.5312 15.9844 12C15.9844 11.4687 16.1875 11 16.5938 10.5937C17 10.1875 17.4688 9.98437 18 9.98437C18.5312 9.98437 19 10.1875 19.4062 10.5937C19.8125 11 20.0156 11.4687 20.0156 12C20.0156 12.5312 19.8125 13 19.4062 13.4062C19 13.8125 18.5312 14.0156 18 14.0156C17.4688 14.0156 17 13.8125 16.5938 13.4062ZM10.5937 13.4062C10.1875 13 9.98437 12.5312 9.98437 12C9.98437 11.4687 10.1875 11 10.5937 10.5937C11 10.1875 11.4687 9.98437 12 9.98437C12.5312 9.98437 13 10.1875 13.4062 10.5937C13.8125 11 14.0156 11.4687 14.0156 12C14.0156 12.5312 13.8125 13 13.4062 13.4062C13 13.8125 12.5312 14.0156 12 14.0156C11.4687 14.0156 11 13.8125 10.5937 13.4062ZM7.40625 10.5938C7.8125 11 8.01562 11.4688 8.01562 12C8.01562 12.5312 7.8125 13 7.40625 13.4062C7 13.8125 6.53125 14.0156 6 14.0156C5.46875 14.0156 5 13.8125 4.59375 13.4062C4.1875 13 3.98437 12.5312 3.98437 12C3.98437 11.4688 4.1875 11 4.59375 10.5938C5 10.1875 5.46875 9.98438 6 9.98438C6.53125 9.98438 7 10.1875 7.40625 10.5938Z" fill="#688FA1"></path></svg>
                            </HeaderButton>
                        </Dropdown>
                    </Tools>
                </FlexHeader>
            </Header>
            <CounterMoney isDragging={snapshot.isDragging}>
                <Money>
                    <Paragraph
                        style={{margin:0}} 
                        className="money_copy"
                        icon={<CopyOutlined />}
                        copyable={
                            { 
                                text: `${status.transactions_count} ${decnum( status.transactions_count,[ 'сделка','сделки','сделок' ] )}: ${status.transactions_amount} ${state.deals_info?.currency_symbol}`
                            }
                        }
                    >
                        {status.transactions_count} {decnum( status.transactions_count,[ 'сделка','сделки','сделок' ] )}: {status.transactions_amount} {state.deals_info?.currency_symbol}
                    </Paragraph>
                </Money>
            </CounterMoney>
            <TransactionList
                listId={id}
                listType="TRANSACTION"
                style={{
                  backgroundColor: snapshot.isDragging ? '#d5e3e3' : 'transparent'
                }}
                transactions={transactions}
                transactionGet={transactionGet}
                state={state}
            />
          </Container>
        )}
      </Draggable>
    );
  }
}
