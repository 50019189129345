import React, { useMemo, useState, useCallback } from "react";
import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../Calendar.css";
import {} from "@ant-design/icons";

moment.locale("ru");
const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const Global = (props) => {
let events = [
    {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2022, 3, 0),
    end: new Date(2022, 3, 1),
    },
    {
    id: 1,
    title: "Long Event",
    start: new Date(2022, 3, 7),
    end: new Date(2022, 3, 10),
    },

    {
    id: 2,
    title: "DTS STARTS",
    start: new Date(2022, 2, 13, 0, 0, 0),
    end: new Date(2022, 2, 20, 0, 0, 0),
    },

    {
    id: 3,
    title: "DTS ENDS",
    start: new Date(2022, 10, 6, 0, 0, 0),
    end: new Date(2022, 10, 13, 0, 0, 0),
    },

    {
    id: 4,
    title: "Some Event",
    start: new Date(2022, 3, 9, 0, 0, 0),
    end: new Date(2022, 3, 9, 0, 0, 0),
    },
    {
    id: 5,
    title: "Conference",
    start: new Date(2022, 3, 11),
    end: new Date(2022, 3, 13),
    desc: "Big conference for important people",
    },
    {
    id: 6,
    title: "Meeting",
    start: new Date(2022, 3, 12, 10, 30, 0, 0),
    end: new Date(2022, 3, 12, 12, 30, 0, 0),
    desc: "Pre-meeting meeting, to prepare for the meeting",
    },
    {
    id: 7,
    title: "Lunch",
    start: new Date(2022, 3, 12, 12, 0, 0, 0),
    end: new Date(2022, 3, 12, 13, 0, 0, 0),
    desc: "Power lunch",
    },
    {
    id: 8,
    title: "Meeting",
    start: new Date(2022, 3, 12, 14, 0, 0, 0),
    end: new Date(2022, 3, 12, 15, 0, 0, 0),
    },
    {
    id: 9,
    title: "Happy Hour",
    start: new Date(2022, 3, 12, 17, 0, 0, 0),
    end: new Date(2022, 3, 12, 17, 30, 0, 0),
    desc: "Most important meal of the day",
    },
    {
    id: 10,
    title: "Dinner",
    start: new Date(2022, 3, 12, 20, 0, 0, 0),
    end: new Date(2022, 3, 12, 21, 0, 0, 0),
    },
    {
    id: 11,
    title: "Birthday Party",
    start: new Date(2022, 3, 13, 7, 0, 0),
    end: new Date(2022, 3, 13, 10, 30, 0),
    },
    {
    id: 12,
    title: "Late Night Event",
    start: new Date(2022, 3, 17, 19, 30, 0),
    end: new Date(2022, 3, 18, 2, 0, 0),
    },
    {
    id: 13,
    title: "Multi-day Event",
    start: new Date(2022, 3, 20, 19, 30, 0),
    end: new Date(2022, 3, 22, 2, 0, 0),
    },
    {
    id: 14,
    title: "Today",
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
    },
];

const [myEvents, setMyEvents] = useState(events);
const [draggedEvent, setDraggedEvent] = useState();
const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
const [counters, setCounters] = useState({ item1: 0, item2: 0 });

const eventPropGetter = useCallback(
    (event) => ({
    ...(event.isDraggable
        ? { className: "isDraggable" }
        : { className: "nonDraggable" }),
    }),
    []
);

const handleDragStart = useCallback((event) => setDraggedEvent(event), []);

const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

const customOnDragOver = useCallback(
    (dragEvent) => {
    // check for undroppable is specific to this example
    // and not part of API. This just demonstrates that
    // onDragOver can optionally be passed to conditionally
    // allow draggable items to be dropped on cal, based on
    // whether event.preventDefault is called
    if (draggedEvent !== "undroppable") {
        console.log("preventDefault");
        dragEvent.preventDefault();
    }
    },
    [draggedEvent]
);

const handleDisplayDragItemInCell = useCallback(
    () => setDisplayDragItemInCell((prev) => !prev),
    []
);

const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
    const { allDay } = event;
    if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
    }

    setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, allDay }];
    });
    },
    [setMyEvents]
);

const defaultDate = useMemo(() => new Date(2022, 3, 12), []);

return (
    <div className="km_calendar">
    <DragAndDropCalendar
        defaultDate={defaultDate}
        defaultView={Views.DAY}
        dragFromOutsideItem={displayDragItemInCell ? dragFromOutsideItem : null}
        draggableAccessor="isDraggable"
        eventPropGetter={eventPropGetter}
        events={events}
        localizer={localizer}
        draggableAccessor={(event) => true}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        views={["day", "week", "month"]}
        messages={{
        next: ">",
        previous: "<",
        today: "Сегодня",
        month: "Мес",
        week: "Нед",
        day: "День",
        agenda: "События",
        date: "Дата",
        time: "Время",
        event: "Событие",
        showMore: (target) => (
            <span className="ml-2" role="presentation">
            {" "}
            ...{target}
            {props.decnum(target, [" событие", " события", " событий"])}
            </span>
        ),
        }}
        headerToolbar={{
        start: "",
        center: "prev, title, next",
        end: "",
        }}
    />
    </div>
);
};

export default Global;
