import React from 'react';
import { Result, Tooltip, Form, Input, Button, Divider, Row, Col, Empty, Space, Table, Select, Skeleton, Modal, List, Typography, Popconfirm, Checkbox } from 'antd';
import { InfoCircleOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;
const { Link, Text } = Typography;

const Global = props => {
    const [form0] = Form.useForm();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [form4] = Form.useForm();

    const days = [];
    for (let i = 1; i <= 730; i++) {
        days.push(<Option key={i} value={i} >{`Через ${i} ${props.decnum( i,[ ' день',' дня',' дней' ] )}`}</Option>);
    }

    const columns1 = [
        {
            title: 'Услуга',
            width: 100,
            render: (record) => <Tooltip title={record.descr}>{record.name}</Tooltip>
        },
        {
            title: <Tooltip title='Срабатывает через N дней после добавления нового пациента'>#1 <InfoCircleOutlined style={{color: '#bebebe'}} /></Tooltip>,
            width: 100,
            render: (record) => 
            !props.state.moreload ? 
                <Select showSearch defaultValue={record.touch_1} style={{ width: '100%' }} onChange={(e) => scriptfupd({[`scsv-1-${record.id}`]:e})}>
                    {days}
                </Select>
            :
                <Skeleton.Button active={true} size='small' shape='round' />
        },
        {
            title: <Tooltip title='Срабатывает через N дней после первого касания'>#2 <InfoCircleOutlined style={{color: '#bebebe'}} /></Tooltip>,
            width: 100,
            render: (record) => 
            !props.state.moreload ? 
                <Select showSearch defaultValue={record.touch_2} style={{ width: '100%' }} onChange={(e) => scriptfupd({[`scsv-2-${record.id}`]:e})}>
                    {days}
                </Select>
            :
                <Skeleton.Button active={true} size='small' shape='round' />
        },
        {
            title: <Tooltip title='Срабатывает через N дней после второго касания'>#3 <InfoCircleOutlined style={{color: '#bebebe'}} /></Tooltip>,
            width: 100,
            render: (record) =>
            !props.state.moreload ? 
                <Select showSearch defaultValue={record.touch_3} style={{ width: '100%' }} onChange={(e) => scriptfupd({[`scsv-3-${record.id}`]:e})}>
                    {days}
                </Select>
            :
                <Skeleton.Button active={true} size='small' shape='round' />
        },
        {
            title: '',
            width: 1,
            render: (record) =>
            <Tooltip title={`Удалить`}>
                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'scriptf_del', 'scsv':`${record.id}` },true)} 
                    title={
                        <Space direction="vertical">
                            <Text>Вы действительно хотите удалить данный сценарий?</Text>
                        </Space>
                    } 
                okText="Да" cancelText="Нет">
                    <Link type="danger" key="a2"><CloseOutlined /></Link>
                </Popconfirm>
            </Tooltip>
        }
    ];

    const columns2 = [
        {
            title: 'Услуга',
            width: 100,
            render: (record) => <Tooltip title={record.descr}>{record.name}</Tooltip>
        },
        {
            title: <Tooltip title='Срабатывает через N дней после последнего приёма данной услуги'>#1 <InfoCircleOutlined style={{color: '#bebebe'}} /></Tooltip>,
            width: 100,
            render: (record) => 
            !props.state.moreload ? 
                <Select showSearch defaultValue={record.touch_1} style={{ width: '100%' }} onChange={(e) => scriptupd({[`scsv-1-${record.id}`]:e})}>
                    {days}
                </Select>
            :
                <Skeleton.Button active={true} size='small' shape='round' />
        },
        {
            title:<Tooltip title='Срабатывает через N дней после первого касания'>#2 <InfoCircleOutlined style={{color: '#bebebe'}} /></Tooltip>,
            width: 100,
            render: (record) => 
            !props.state.moreload ? 
                <Select showSearch defaultValue={record.touch_2} style={{ width: '100%' }} onChange={(e) => scriptupd({[`scsv-2-${record.id}`]:e})}>
                    {days}
                </Select>
            :
                <Skeleton.Button active={true} size='small' shape='round' />
        },
        {
            title: <Tooltip title='Срабатывает через N дней после второго касания'>#3 <InfoCircleOutlined style={{color: '#bebebe'}} /></Tooltip>,
            width: 100,
            render: (record) =>
            !props.state.moreload ? 
                <Select showSearch defaultValue={record.touch_3} style={{ width: '100%' }} onChange={(e) => scriptupd({[`scsv-3-${record.id}`]:e})}>
                    {days}
                </Select>
            :
                <Skeleton.Button active={true} size='small' shape='round' />
        },
        {
            title: '',
            width: 1,
            render: (record) =>
            <Tooltip title={`Удалить`}>
                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'script_del', 'scsv':`${record.id}` },true)} 
                    title={
                        <Space direction="vertical">
                            <Text>Вы действительно хотите удалить данный сценарий?</Text>
                        </Space>
                    } 
                okText="Да" cancelText="Нет">
                    <Link type="danger" key="a2"><CloseOutlined /></Link>
                </Popconfirm>
            </Tooltip>
        }
    ];

    const columns3 = [
        {
            title: 'Акция',
            width: 100,
            render: (record) => <Tooltip title={record.descr}>{record.name}</Tooltip>
        },
        {
            title: <Tooltip title='Срабатывает через N дней после внесения анкеты'>Срок <InfoCircleOutlined style={{color: '#bebebe'}} /></Tooltip>,
            width: 100,
            render: (record) => 
            !props.state.moreload ? 
                <Select showSearch defaultValue={record.touch_1} style={{ width: '100%' }} onChange={(e) => scriptpotupd({[`scsv-1-${record.id}`]:e})}>
                    {days}
                </Select>
            :
                <Skeleton.Button active={true} size='small' shape='round' />
        },
        {
            title: '',
            width: 1,
            render: (record) =>
            <Tooltip title={`Удалить`}>
                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'scriptpot_del', 'scsv':`${record.id}` },true)} 
                    title={
                        <Space direction="vertical">
                            <Text>Вы действительно хотите удалить данный сценарий?</Text>
                        </Space>
                    } 
                okText="Да" cancelText="Нет">
                    <Link type="danger" key="a2"><CloseOutlined /></Link>
                </Popconfirm>
            </Tooltip>
        }
    ];

    //* Отправка формы
    async function conn_add_ser_rec(v) {
        v['method'] = 'conn_add_ser_rec';
        await props.api(v,false);
    }

    //* Отправка формы
    async function conn_add_noser_rec(v) {
        v['method'] = 'conn_add_noser_rec';
        await props.api(v,false);
    }
    
    //* Отправка формы
    async function conn_add_pt_rec(v) {
        v['method'] = 'conn_add_pt_rec';
        await props.api(v,false);
    }

    //* Отправка формы
    async function conn_add_pt_ser(v) {
        v['method'] = 'conn_add_pt_ser';
        await props.api(v,false);
    }

    //* Отправка формы
    async function scriptadd(v) {
        v['method'] = 'script_add';
        await props.api(v,false);
    }

    //* Отправка формы
     async function scriptfadd(v) {
        v['method'] = 'scriptf_add';
        await props.api(v,false);
    }

    //* Отправка формы
    async function scriptpotadd(v) {
        v['method'] = 'scriptpot_add';
        await props.api(v,false);
    }

    async function scriptupd(v) {
        v['method'] = 'script_upd';
        await props.api(v,false);
    }

    async function scriptpotupd(v) {
        v['method'] = 'scriptpot_upd';
        await props.api(v,false);
    }
    
    async function scriptfupd(v) {
        v['method'] = 'scriptf_upd';
        await props.api(v,false);
    }

    //* Вызов окна
    async function scriptpushadd() {
        await props.modal('script_add');
    }

    //* Вызов окна
    async function scriptfpushadd() {
        await props.modal('scriptf_add');
    }

    //* Вызов окна
    async function scriptpotpushadd() {
        await props.modal('scriptpot_add');
    }

    //* Вызов окна
    async function ser_recpushadd() {
        await props.modal('conn_add_ser_rec');
    }

    //* Вызов окна
    async function noser_recpushadd() {
        await props.modal('conn_add_noser_rec');
    }

    //* Вызов окна
    async function pt_recpushadd() {
        await props.modal('conn_add_pt_rec');
    }

    //* Вызов окна
    async function pt_serpushadd() {
        await props.modal('conn_add_pt_ser');
    }

    return (
        <React.Fragment>
            <Modal className="modal-mini bg_chester" key="m01" title="Добавление сценария" visible={props.state.modal === 'scriptpot_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление сценария</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form4" form={form4} onFinish={scriptpotadd} className="form" layout="vertical">
                            <Form.Item name={`stid`} label='Акция' rules={[{ required: true, message: `Пожалуйста, выберите акцию!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    { props.state.stocks.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>
                                            {item.name}
                                            {item.text.length > 0 &&
                                                <span style={{fontSize:10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {` — ${item.text}`}
                                                </span>
                                            }
                                        </Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Form.Item name='touch_1' label='Срок' rules={[{ required: true, message: `Пожалуйста, выберите срок!` }]}>
                                <Select showSearch style={{ width: '100%' }}>
                                    {days}
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m01" title="Добавление сценария" visible={props.state.modal === 'scriptf_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление сценария</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form4" form={form4} onFinish={scriptfadd} className="form" layout="vertical">
                            <Form.Item name={`svid`} label='Услуга' rules={[{ required: true, message: `Пожалуйста, выберите услугу!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    { props.state.services.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>
                                            {item.name} — {item.price > 0 ? item.price+'₽' : (item.price < 0 ? 'Бесплатно' : '')}
                                            {item.descr.length > 0 &&
                                                <span style={{fontSize:10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {` — ${item.descr}`}
                                                </span>
                                            }
                                        </Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Form.Item name='touch_1' label='1 касание' rules={[{ required: true, message: `Пожалуйста, выберите срок!` }]}>
                                <Select showSearch style={{ width: '100%' }}>
                                    {days}
                                </Select>
                            </Form.Item>
                            <Form.Item name='touch_2' label='2 касание' rules={[{ required: true, message: `Пожалуйста, выберите срок!` }]}>
                                <Select showSearch style={{ width: '100%' }}>
                                    {days}
                                </Select>
                            </Form.Item>
                            <Form.Item name='touch_3' label='3 касание' rules={[{ required: true, message: `Пожалуйста, выберите срок!` }]}>
                                <Select showSearch style={{ width: '100%' }}>
                                    {days}
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m0" title="Добавление сценария" visible={props.state.modal === 'script_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление сценария</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form0" form={form0} onFinish={scriptadd} className="form" layout="vertical">
                            <Form.Item name={`svid`} label='Услуга' rules={[{ required: true, message: `Пожалуйста, выберите услугу!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    { props.state.services.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>
                                            {item.name} — {item.price > 0 ? item.price+'₽' : (item.price < 0 ? 'Бесплатно' : '')}
                                            {item.descr.length > 0 &&
                                                <span style={{fontSize:10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {` — ${item.descr}`}
                                                </span>
                                            }
                                        </Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Form.Item name='touch_1' label='1 касание' rules={[{ required: true, message: `Пожалуйста, выберите срок!` }]}>
                                <Select showSearch style={{ width: '100%' }}>
                                    {days}
                                </Select>
                            </Form.Item>
                            <Form.Item name='touch_2' label='2 касание' rules={[{ required: true, message: `Пожалуйста, выберите срок!` }]}>
                                <Select showSearch style={{ width: '100%' }}>
                                    {days}
                                </Select>
                            </Form.Item>
                            <Form.Item name='touch_3' label='3 касание' rules={[{ required: true, message: `Пожалуйста, выберите срок!` }]}>
                                <Select showSearch style={{ width: '100%' }}>
                                    {days}
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m1" title="Связь" visible={props.state.modal === 'conn_add_ser_rec'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Связь</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form1" form={form1} onFinish={conn_add_ser_rec} className="form" layout="vertical">
                            <Form.Item name={`svid`} label='Услуга' rules={[{ required: true, message: `Пожалуйста, выберите услугу!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    { props.state.services.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>
                                            {item.name} — {item.price > 0 ? item.price+'₽' : (item.price < 0 ? 'Бесплатно' : '')}
                                            {item.descr.length > 0 &&
                                                <span style={{fontSize:10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {` — ${item.descr}`}
                                                </span>
                                            }
                                        </Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Form.Item name={`recid`} label='Рекомендация' rules={[{ required: true, message: `Пожалуйста, выберите рекомендацию!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    { props.state.rec.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>
                                            {item.name}
                                            {item.descr.length > 0 &&
                                                <span style={{fontSize:10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {` — ${item.descr}`}
                                                </span>
                                            }
                                        </Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m1" title="Связь" visible={props.state.modal === 'conn_add_noser_rec'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Связь</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form1" form={form1} onFinish={conn_add_noser_rec} className="form" layout="vertical">
                            <Form.Item name={`svid`} label='Услуга' rules={[{ required: true, message: `Пожалуйста, выберите услугу!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    { props.state.services.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>
                                            {item.name} — {item.price > 0 ? item.price+'₽' : (item.price < 0 ? 'Бесплатно' : '')}
                                            {item.descr.length > 0 &&
                                                <span style={{fontSize:10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {` — ${item.descr}`}
                                                </span>
                                            }
                                        </Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Form.Item name={`recid`} label='Рекомендация' rules={[{ required: true, message: `Пожалуйста, выберите рекомендацию!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    { props.state.rec.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>
                                            {item.name}
                                            {item.descr.length > 0 &&
                                                <span style={{fontSize:10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {` — ${item.descr}`}
                                                </span>
                                            }
                                        </Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m2" title="Связь" visible={props.state.modal === 'conn_add_pt_rec'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Связь</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form2" form={form2} onFinish={conn_add_pt_rec} className="form" layout="vertical">
                            <Form.Item name={`ptid`} label='Тип пациента' rules={[{ required: true, message: `Пожалуйста, выберите тип сотрудника!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    <Option value="-1">Для всех</Option>
                                    { props.state.patientst.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>{item.name}</Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Form.Item name={`recid`} label='Рекомендация' rules={[{ required: true, message: `Пожалуйста, выберите рекомендацию!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    { props.state.rec.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>
                                            {item.name}
                                            {item.descr.length > 0 &&
                                                <span style={{fontSize:10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {` — ${item.descr}`}
                                                </span>
                                            }
                                        </Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" key="m3" title="Связь" visible={props.state.modal === 'conn_add_pt_ser'} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Связь</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form3" form={form3} onFinish={conn_add_pt_ser} className="form" layout="vertical">
                            <Form.Item name={`ptid`} label='Тип пациента' rules={[{ required: true, message: `Пожалуйста, выберите тип сотрудника!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    <Option value="-1">Для всех</Option>
                                    { props.state.patientst.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>{item.name}</Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Form.Item name={`svid`} label='Услуга' rules={[{ required: true, message: `Пожалуйста, выберите услугу!` }]}>
                                <Select showSearch optionFilterProp="children">
                                    { props.state.services.map( (item, index) => 
                                        <Option disabled={Number(item.block)} key={index} value={item.id}>
                                            {item.name} — {item.price > 0 ? item.price+'₽' : (item.price < 0 ? 'Бесплатно' : '')}
                                            {item.descr.length > 0 &&
                                                <span style={{fontSize:10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {` — ${item.descr}`}
                                                </span>
                                            }
                                        </Option>
                                    ) }
                                </Select>
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Row style={{flexDirection:'column'}} className="column900 wowload">
                <Col key="k1_reviews" className="shadowscreen" >
                    <div className="header_title">Услуга — рекомендация (услуга уже оказывалась)</div>
                    <div style={{padding:20}}>
                        {'ser_rec' in props.state.scripts ?
                            <React.Fragment>
                                <List
                                    className="listscroll_script"
                                    itemLayout="horizontal"
                                    dataSource={props.state.scripts.ser_rec}
                                    renderItem={(item,index) => (
                                        <List.Item actions={[
                                            <Tooltip title={`Удалить`}>
                                                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'conn_del_ser_rec', 'serrecid':`${item.id}` },true)} 
                                                    title={
                                                        <Space direction="vertical">
                                                            <Text>Вы действительно хотите удалить данную связь?</Text>
                                                        </Space>
                                                    } 
                                                okText="Да" cancelText="Нет">
                                                    <Link type="danger" key="a2"><CloseOutlined /></Link>
                                                </Popconfirm>
                                            </Tooltip>
                                        ]}>
                                            <List.Item.Meta
                                                title={<React.Fragment><Tooltip title={item.s_descr}>{item.s_name}</Tooltip> — {item.r_name}</React.Fragment>}
                                            />
                                        
                                        </List.Item>
                                    )}
                                />
                                <div className="fcenter">
                                    <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => ser_recpushadd()} />
                                </div>
                            </React.Fragment>
                        :
                            <Empty description="Информация о связях недоступна" />
                        }
                    </div>
                </Col>
                <Col key="k1_reviews" className="shadowscreen" >
                    <div className="header_title">Услуга — рекомендация (услуга не оказывалась)</div>
                    <div style={{padding:20}}>
                        {'noser_rec' in props.state.scripts ?
                            <React.Fragment>
                                <List
                                    className="listscroll_script"
                                    itemLayout="horizontal"
                                    dataSource={props.state.scripts.noser_rec}
                                    renderItem={(item,index) => (
                                        <List.Item actions={[
                                            <Tooltip title={`Удалить`}>
                                                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'conn_del_noser_rec', 'serrecid':`${item.id}` },true)} 
                                                    title={
                                                        <Space direction="vertical">
                                                            <Text>Вы действительно хотите удалить данную связь?</Text>
                                                        </Space>
                                                    } 
                                                okText="Да" cancelText="Нет">
                                                    <Link type="danger" key="a2"><CloseOutlined /></Link>
                                                </Popconfirm>
                                            </Tooltip>
                                        ]}>
                                            <List.Item.Meta
                                                title={<React.Fragment><Tooltip title={item.s_descr}>{item.s_name}</Tooltip> — {item.r_name}</React.Fragment>}
                                            />
                                        
                                        </List.Item>
                                    )}
                                />
                                <div className="fcenter">
                                    <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => noser_recpushadd()} />
                                </div>
                            </React.Fragment>
                        :
                            <Empty description="Информация о связях недоступна" />
                        }
                    </div>
                </Col>
                <Col key="k2_reviews" className="shadowscreen" >
                    <div className="header_title">Тип пациента — рекомендация</div>
                    <div style={{padding:20}}>
                        {'pt_rec' in props.state.scripts ?
                            <React.Fragment>
                                <List
                                    className="listscroll_script"
                                    itemLayout="horizontal"
                                    dataSource={props.state.scripts.pt_rec}
                                    renderItem={(item,index) => (
                                        <List.Item actions={[
                                            <Tooltip title={`Удалить`}>
                                                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'conn_del_pt_rec', 'ptrecid':`${item.id}` },true)} 
                                                    title={
                                                        <Space direction="vertical">
                                                            <Text>Вы действительно хотите удалить данную связь?</Text>
                                                        </Space>
                                                    } 
                                                okText="Да" cancelText="Нет">
                                                    <Link type="danger" key="a2"><CloseOutlined /></Link>
                                                </Popconfirm>
                                            </Tooltip>
                                        ]}>
                                            <List.Item.Meta
                                                title={<React.Fragment>{item.p_name} — {item.r_name}</React.Fragment>}
                                            />
                                        
                                        </List.Item>
                                    )}
                                />
                                <div className="fcenter">
                                    <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => pt_recpushadd()} />
                                </div>
                            </React.Fragment>
                        :
                            <Empty description="Информация о связях недоступна" />
                        }
                    </div>
                </Col>
                <Col key="k3_reviews" className="shadowscreen" >
                    <div className="header_title">Тип пациента — услуга</div>
                    <div style={{padding:20}}>
                        {'pt_ser' in props.state.scripts ?
                            <React.Fragment>
                                <List
                                    className="listscroll_script"
                                    itemLayout="horizontal"
                                    dataSource={props.state.scripts.pt_ser}
                                    renderItem={(item,index) => (
                                        <List.Item actions={[
                                            <Tooltip title={`Удалить`}>
                                                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'conn_del_pt_ser', 'ptserid':`${item.id}` },true)} 
                                                    title={
                                                        <Space direction="vertical">
                                                            <Text>Вы действительно хотите удалить данную связь?</Text>
                                                        </Space>
                                                    } 
                                                okText="Да" cancelText="Нет">
                                                    <Link type="danger" key="a2"><CloseOutlined /></Link>
                                                </Popconfirm>
                                            </Tooltip>
                                        ]}>
                                            <List.Item.Meta
                                                title={<React.Fragment>{item.p_name} — <Tooltip title={item.s_descr}>{item.s_name}</Tooltip></React.Fragment>}
                                            />
                                        
                                        </List.Item>
                                    )}
                                />
                                <div className="fcenter">
                                    <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => pt_serpushadd()} />
                                </div>
                            </React.Fragment>
                        :
                            <Empty description="Информация о связях недоступна" />
                        }
                    </div>
                </Col>
                <Col key="k3_reviewsf" className="shadowscreen" >
                    <div className="header_title">Сценарии первичных приёмов</div>
                    <div style={{padding:20}}>
                        {'scripts_serf' in props.state.scripts ?
                            <React.Fragment>
                                <Table locale={{ emptyText: <Empty description="Список сценариев пуст" />}} dataSource={props.state.scripts.scripts_serf} scroll={{ x: 400 }} rowKey="table1" columns={columns1}></Table>
                                <div className="fcenter">
                                    <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => scriptfpushadd()} />
                                </div>
                            </React.Fragment>
                        :
                            <Empty description="Информация о сценариях недоступна" />
                        }
                    </div>
                </Col>
                <Col key="k3_reviews" className="shadowscreen" >
                    <div className="header_title">Сценарии повторных приёмов</div>
                    <div style={{padding:20}}>
                        {'scripts_ser' in props.state.scripts ?
                            <React.Fragment>
                                <Table locale={{ emptyText: <Empty description="Список сценариев пуст" />}} dataSource={props.state.scripts.scripts_ser} scroll={{ x: 400 }} rowKey="table1" columns={columns2}></Table>
                                <div className="fcenter">
                                    <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => scriptpushadd()} />
                                </div>
                            </React.Fragment>
                        :
                            <Empty description="" />
                        }
                    </div>
                </Col>
                <Col key="k4_potential" className="shadowscreen" >
                    <div className="header_title">Сценарии для потенциальных</div>
                    <div style={{padding:20}}>
                        {'scripts_ser' in props.state.scripts ?
                            <React.Fragment>
                                <Table locale={{ emptyText: <Empty description="Список сценариев пуст" />}} dataSource={props.state.scripts.scripts_pot} scroll={{ x: 400 }} rowKey="table1" columns={columns3}></Table>
                                <div className="fcenter">
                                    <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => scriptpotpushadd()} />
                                </div>
                            </React.Fragment>
                        :
                            <Empty description="Информация о сценариях недоступна" />
                        }
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;